class CarouselManager {
  constructor(selectorOrElement, options, customHandlers = null) {
    // Check if the argument is a string or a DOM element
    this.element = typeof selectorOrElement === 'string' ? document.querySelector(selectorOrElement) : selectorOrElement;

    if (this.element) {
      this.splide = new Splide(this.element, options);
      if (customHandlers) {
        customHandlers(this.splide);
      }
      this.splide.mount();
    }
  }
}

document.addEventListener("DOMContentLoaded", function () {

  // Home Banner Carousel
  new CarouselManager('.js-home-banner-slider', {
    type: 'loop',
    autoplay: true,
    perPage: 1,
    perMove: 1,
    pagination: true,
    arrows: false,
    interval: 6000,
    pauseOnHover: false,
    }, (splide) => {
    splide.on('pagination:mounted', (data) => {
      const pagination = data.list;
      pagination.classList.add('banner__pagination');
      data.items.forEach((item) => {
        item.button.classList.add('banner__listbutton');
      });
      data.list.childNodes.forEach((item) => {
        item.classList.add('banner__paginationlist');
      });
    });
  });

  // Home Interview Carousel
  new CarouselManager('.js-home-interview-slider', {
    type: 'loop',
    autoplay: false,
    perPage: 3,
    perMove: 1,
    focus: 'center',
    pagination: false,
    arrows: true,
  });

  // Home Customer Carousel
  new CarouselManager('.js-home-customer-slider', {
    type: 'loop',
    autoplay: true,
    interval: 3000,
    pauseOnHover: true,
    perPage: 2,
    perMove: 1,
    pagination: false,
    arrows: false,
    direction: 'ttb',
    height: '30rem',
    wheel: true,
    wheelSleep: 8000,
  });

  // Home Trust Carousel
  new CarouselManager('.js-home-trust-slider', {
    type: 'loop',
    autoplay: true,
    perPage: 3,
    pagination: false,
    arrows: false,
  });

  // Home Benefit Doctor Carousel
  new CarouselManager('.js-home-benefitdoctor-slider', {
    type: 'slide',
    perPage: 4,
    pagination: true,
    arrows: false,
    gap: 15,
    breakpoints: {
      767: {
        type: 'loop',
        autoWidth: true,
        omitEnd: true,
        pagination: false ,
        autoplay: true,
        padding: '10%',
        gap: 10,
      },
      1024: {
        perPage: 3,
      },
    },
  }, (splide) => {
    splide.on('pagination:mounted', (data) => {
      data.items.forEach((item) => {
        item.button.classList.add('benefit-pagination');
      });
      data.list.childNodes.forEach((item) => {
        item.classList.add('li-benefit');
      });
    });
  });

  // Home Benefit Other Carousel
  new CarouselManager('.js-home-benefitother-slider', {
    type: 'slide',
    perPage: 1,
    pagination: true,
    arrows: false,
    gap: 15,
    destroy: true,
    breakpoints: {
      767: {
        perPage: 1,
        autoplay: true,
      },
      1024: {
        destroy: false,
      },
    },
  }, (splide) => {
    splide.on('pagination:mounted', (data) => {
      data.items.forEach((item) => {
        item.button.classList.add('benefit-pagination');
      });
      data.list.childNodes.forEach((item) => {
        item.classList.add('li-benefit');
      });
    });
  });

  // Home Benefit Other Carousel
  new CarouselManager('.js-home-benefitgeneral-slider', {
    type: 'slide',
    perPage: 1,
    pagination: true,
    arrows: false,
    gap: 15,
    destroy: true,
    breakpoints: {
      767: {
        perPage: 1,
        autoplay: true,
      },
      1024: {
        destroy: false,
      },
    },
  }, (splide) => {
    splide.on('pagination:mounted', (data) => {
      data.items.forEach((item) => {
        item.button.classList.add('benefit-pagination');
      });
      data.list.childNodes.forEach((item) => {
        item.classList.add('li-benefit');
      });
    });
  });

  // Home How Carousel
  new CarouselManager('.js-home-how-slider', {
    type: 'loop',
    autoplay: true,
    pagination: false,
    arrows: false,
    interval: 6000,
    pauseOnHover: false,
    gap: 15,
    focus: 'center',
    destroy: true,
    padding: '10%',
    breakpoints: {
      767: {},
      992: {
        destroy: false,
      },
    },
  });

  // Price Carousel
  document.querySelectorAll('.js-price-slider').forEach((slider) => {
    new CarouselManager(slider, {
      type: 'loop',
      autoplay: true,
      perPage: 1,
      perMove: 1,
      pagination: false,
      arrows: true,
      gap: 15,
      destroy: true,
      breakpoints: {
        767: {
          destroy: false,
        },
      },
    });
  });

  // Home Service Carousel with custom handling for heights and progress bar
  new CarouselManager('.js-home-service-slider', {
    type: 'slide',
    perPage: 1,
    perMove: 1,
    pagination: false,
    arrows: true, 
    autoHeight: true,
    gap: '4.75rem',
    perPage: 1,
    perMove: 1,
    breakpoints: {
      992: {
        perPage: 1,
        perMove: 1,
        arrows: false,
        pagination: false,
        autoHeight: true,
      },
    },
  }, (splide) => {
    const heightMap = {};
    const slides = document.querySelectorAll("#serviceSlider .splide__slide");

    function initializeHeightMap() {
      slides.forEach((slide, index) => {
        if (!slide.classList.contains('splide__slide--clone')) {
          slide.style.maxHeight = 'none';
          heightMap[index] = slide.scrollHeight;
        }
      });
    }

    function adjustSlideHeights() {
      const currentIndex = splide.index;
      const currentSlideHeight = heightMap[currentIndex];
      slides.forEach((slide) => {
        slide.style.maxHeight = currentSlideHeight + 'px';
      });
      const track = document.querySelector("#serviceSlider .splide__track");
      if (track) {
        track.style.height = currentSlideHeight + 'px';
      }
    }

    splide.on("mounted", () => {
      initializeHeightMap();
      adjustSlideHeights();
    });

    splide.on("move", () => {
      requestAnimationFrame(adjustSlideHeights);
    });

    splide.on('mounted move', () => {
      const bar = document.querySelector('.js-slide-bar');
      if (bar && bar.style) {
        const totalSlides = splide.Components.Elements.slides.length;
        const currentIndex = splide.index;
        const rate = Math.min((currentIndex + 1) / totalSlides, 1);
        bar.style.width = String(100 * rate) + '%';
      }
    });
  });
});
